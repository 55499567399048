* {
  box-sizing: border-box;
}

:root {
  --drizzle-green: rgb(67 160 71);
  --drizzle-yellow: rgb(241 196 15);
  --drizzle-orange: rgb(255 87 34);
  --drizzle-red: rgb(233 30 30);
  --drizzle-blue: rgb(83 109 254);
  --drizzle-teal: rgb(0 150 136);
}

body {
  margin: 0;
  padding: 0;
  background: #eee;
}

#app {
  height: 100vh;
  width: 100%;
}

a {
  color: var(--drizzle-green);
  text-decoration: none;
}

a:hover {
  color: var(--drizzle-green);
  text-decoration: underline;
}
